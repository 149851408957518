import logo from './logo.svg';
//import logo from './Profile-Logo-Stacked-Black-1600x500.png';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Profile from "./Profile";
import Target from "./Target";
import { RequireToken } from "./Auth";
import { RequireUser } from "./Auth";
import React, { Component } from 'react';  

class App extends Component {

  constructor (props) {
  super(props)
    this.state = {
	optionsLoaded: false,
	options: null,
	isLoading: false 
    }
  }

render() {
  return (
  <div className ="App">
    <Routes>
      <Route path="/" element = {<Login/>}/>
      <Route
        path="/profile"
        element={
          <RequireUser>
	    <div className='App-div'>
              <Profile />
	    </div>
          </RequireUser>
        }
    />
    </Routes>
    </div>
  );
}
}
export default App;
