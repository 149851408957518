import { useNavigate } from "react-router";
import { fetchToken, setToken } from "./Auth";
import { fetchUser, setUser } from "./Auth";
import { fetchPadX, setPadX } from "./Setters";
import { useState } from "react";
import axios from "axios";

//import Logo from './Profile-Logo-S.gif';
import Logo from './Profile-Logo-240x80.png';

import './Login.css'

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  //check to see if the fields are not empty
  const login = () => {
    if (username === "") {
      return;
    } else {
      // make api call to our backend. we'll leave thisfor later
      //var url = `https://localhost:5000/user/?payload=${username}`;
      var url = `https://profile.greengecko.co.nz:5000/user/?payload=${username}`;

      console.log ( url )
	    	
	    
      axios({
	method: 'get',
        url: url,
      })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          console.log(response.data);
          if (response.data) {
 	    setUser ( response.data );
            navigate("/profile");
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    }
  };

  return (
    <div className="main">
      <img src={Logo} alt="Profile Design Logo"/>
      <div style={{ marginTop: 30 }}>
        {fetchUser() ? (
          <h3>Please Login</h3>
        ) : (
            <form>
              <label> Username:</label>
              <input
                type="text"
		required="true"
		Placeholder="Enter Your Username"
                onChange={(e) => setUsername(e.target.value)}
              />

	      <div className="wrap">
              <button type="button" onClick={login}>
                Submit
              </button>
	      </div>
            </form>
        )}
      </div>
    </div>
  );
}
